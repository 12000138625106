import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import styles from "./DeviceDetails.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faBolt,
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import DeRegisterConfirmationDialogBox from "../common/DialogBox/DeRegisterConfirmation/DeRegisterConfirmationDialogBox";
import DeRegisterSuccess from "../common/DialogBox/DeRegisterSuccess/DeRegisterSuccess";
import {
  deRegisterDevice,
  getDeviceAlerts,
  getDeviceInfo,
} from "../../api/api";

const DeviceDetails = ({ deviceId, onClose }) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [device, setDevice] = useState(null);
  const [copied, setCopied] = useState(false);
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingDeviceTable, setLoadingDeviceTable] = useState(true);
  const [alerts, setAlerts] = useState([]);
  const initialStartDate = new Date();
  initialStartDate.setDate(initialStartDate.getDate() - 1);
  const [startTime, setStartTime] = useState(initialStartDate);
  const [endTime, setEndTime] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleDeregister = () => {
    setShowConfirmationDialog(true);
  };

  const getStatusColor = (status) => {
    return status === "ACTIVE" ? styles.active : styles.inactive;
  };
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingDeviceTable(true);
        const response = await getDeviceInfo(deviceId);
        setDevice(response.data);
      } catch (error) {
        console.error("Error fetching device info:", error);
      } finally {
        setLoadingDeviceTable(false);
      }
    };
    fetchData();
  }, [deviceId, device?.last_heartbeat]);

  const deregisterDevice = async () => {
    try {
      await deRegisterDevice(deviceId);
      setShowConfirmationDialog(false);
      setShowSuccessDialog(true);
    } catch (error) {
      setShowSuccessDialog(true);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date) ? date.toISOString().split("T")[0] : "N/A";
  };

  let deviceContent;
  if (loadingDeviceTable) {
    deviceContent = (
      <tr>
        <td colSpan="1" className={styles.detailsSpinner}>
          <div className={styles.detailsLoader}></div>
        </td>
      </tr>
    );
  } else {
    deviceContent = (
      <>
        <tr>
          <td className={styles.tableHead}>Device Serial No</td>
          <td className={styles.deviceTableTd}>{device?.serial_no}</td>
        </tr>
        <tr>
          <td className={styles.tableHead}>Device Name</td>
          <td className={styles.deviceTableTd}>{device?.device_name}</td>
        </tr>
        <tr>
          <td className={styles.tableHead}>Device Status</td>
          <td className={styles.deviceTableTd}>
            <div>
              <button className={getStatusColor(device?.status)}>
                {device?.status}
              </button>
            </div>
          </td>
        </tr>
        <tr>
          <td className={styles.tableHead}>Last HeartBeat</td>
          <td className={styles.deviceTableTd}>
            {device?.last_heartbeat ? formatDate(device.last_heartbeat) : "N/A"}
          </td>
        </tr>
        <tr>
          <td className={styles.tableHead}>Last Event</td>
          <td className={styles.deviceTableTd}>{device?.last_event}</td>
        </tr>
        <tr>
          <td className={styles.tableHead}>Pass Key</td>
          <td
            onMouseEnter={() => {
              setHover(true);
            }}
            onMouseLeave={() => {
              setHover(false);
            }}
            className={styles.deviceTableTd}
          >
            {!hover ? (
              <span>{device?.pass_key}</span>
            ) : !copied ? (
              <span
                className={styles.passkeyText}
                onClick={() => copyToClipboard(device?.pass_key)}
              >
                Copy
              </span>
            ) : (
              <span className={styles.passkeyText}>Copied</span>
            )}
          </td>
        </tr>
      </>
    );
  }

  const fetchAlerts = async () => {
    try {
      const startTimeISO = startTime.toISOString();
      const endTimeISO = endTime.toISOString();
      const data = await getDeviceAlerts(
        deviceId,
        startTimeISO,
        endTimeISO,
        currentPage
      );
      setAlerts(data.data);
      setTotalPages(Math.ceil(data.total_count / data.page_limit));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching  Device Alerts:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAlerts();
  }, [currentPage]);

  const handleFetchClick = async () => {
    try {
      fetchAlerts();
    } catch (error) {
      console.error("Error fetching Device Alerts:", error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  let content;
  if (loading) {
    content = (
      <tr>
        <td colSpan="4" className={styles.spinner}>
          <div className={styles.loader}></div>
        </td>
      </tr>
    );
  } else if (!Array.isArray(alerts) || alerts.length === 0) {
    content = (
      <tr className={styles.noData}>
        <td colSpan="4">
          {device ? ` No Alerts for ${device.device_name} ` : "No Alerts"}
        </td>
      </tr>
    );
  } else {
    content = alerts.map((error, index) => (
      <tr className={styles.bodyTr} key={index}>
        <td>{error.code}</td>
        <td>{new Date(error.created_at).toLocaleString()}</td>
        <td>{error.message || "N/A"}</td>
        <td>{new Date(error.alert_timestamp).toLocaleString()}</td>
      </tr>
    ));
  }

  const prevButtonClasses = classNames(styles.prevButton, {
    [styles.disabled]: currentPage === 1 || alerts.length === 0,
  });

  const nextButtonClasses = classNames(styles.nextButton, {
    [styles.disabled]: currentPage === totalPages || alerts.length === 0,
  });

  const pagination = (
    <div className={styles.pagination}>
      <FontAwesomeIcon
        icon={faCircleChevronLeft}
        alt="previous"
        className={prevButtonClasses}
        title="Previous"
        onClick={() => {
          if (!(currentPage === 1 || alerts.length === 0)) {
            handlePageChange(currentPage - 1);
          }
        }}
      />
      <span className={styles.pageSpan}>{currentPage}</span>
      <FontAwesomeIcon
        icon={faCircleChevronRight}
        alt="next"
        title="Next"
        className={nextButtonClasses}
        onClick={() => {
          if (!(currentPage === totalPages || alerts.length === 0)) {
            handlePageChange(currentPage + 1);
          }
        }}
      />
    </div>
  );

  return (
    <div className={styles.container}>
      <Helmet>
        <title>
          {device
            ? ` ${device.device_name} | LIFELENZ`
            : "Device Details | LIFELENZ"}
        </title>
      </Helmet>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <span className={styles.headerSpan}>
            Time Clock Device | {device?.device_name}
          </span>
          <FontAwesomeIcon
            icon={faXmark}
            alt="Back"
            className={styles.backButton}
            onClick={onClose}
          />
        </div>
      </div>
      <div className={styles.mainTables}>
        <main className={styles.mainTable}>
          <section className={styles.tableBody}>
            <div className={styles.deviceHeader}>
              <span className={styles.deviceHeaderSpan}>
                Time Clock Device | Info
              </span>
            </div>
            <table className={styles.deviceTable}>
              <tbody className={styles.tbody}>{deviceContent}</tbody>
            </table>
          </section>
          <section className={styles.buttons}>
            <div className={styles.closeDetails}>
              <button className={styles.closeBtn} onClick={onClose}>
                Close
              </button>
            </div>
            <div className={styles.deRegisterDevice}>
              <button
                className={styles.deregisterBtn}
                onClick={handleDeregister}
              >
                Deregister
              </button>
            </div>
          </section>
        </main>

        <main className={styles.mainTable2}>
          <section className={styles.tableHeader2}>
            <div className={styles.headerContent2}>
              <span className={styles.alertHeaderSpan}>
                Time Clock Device | Alerts
              </span>
              <div className={styles.datePickers}>
                <div
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Select the start date"
                  data-tooltip-place="top"
                >
                  <DatePicker
                    className={styles.datePicker}
                    selected={startTime}
                    onChange={(date) => setStartTime(date)}
                    showTimeSelect
                    dateFormat="PPp"
                    placeholderText="Start Date"
                  />
                </div>
                <p className={styles.p}>- </p>
                <div
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Select the end date"
                  data-tooltip-place="top"
                >
                  <DatePicker
                    className={styles.datePicker}
                    selected={endTime}
                    onChange={(date) => setEndTime(date)}
                    showTimeSelect
                    dateFormat="PPp"
                    minDate={startTime}
                    placeholderText="End Date"
                  />
                </div>
                <FontAwesomeIcon
                  icon={faBolt}
                  alt="fetch"
                  className={styles.fetchButton}
                  onClick={handleFetchClick}
                />
              </div>
            </div>
          </section>
          <section className={styles.tableBody2}>
            <table className={styles.alertsTable}>
              <thead>
                <tr>
                  <th>Alert Code</th>
                  <th>Time Created</th>
                  <th>Alerts Message</th>
                  <th>Alerts TimeStamp</th>
                </tr>
              </thead>
              <tbody>{content}</tbody>
            </table>
          </section>
          <section className={styles.tableFooter}>{pagination}</section>
        </main>
      </div>
      {showConfirmationDialog && (
        <DeRegisterConfirmationDialogBox
          deviceName={device?.device_name}
          deviceId={device?.id}
          onClose={() => setShowConfirmationDialog(false)}
          onDeregister={deregisterDevice}
        />
      )}

      {showSuccessDialog && (
        <DeRegisterSuccess
          message="Device deregistered successfully!"
          onClose={() => {
            setShowSuccessDialog(false);
            onClose();
          }}
        />
      )}
    </div>
  );
};

export default DeviceDetails;
