import React, { useState } from "react";
import styles from "./RegisterDeviceDialogBox.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboard,
  faClipboardCheck,
} from "@fortawesome/free-solid-svg-icons";

export default function RegisterDeviceDialogBox({
  dialogData,
  error,
  onClose,
}) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  let title, bodyContent;
  if (dialogData.success) {
    title = dialogData.success;
    bodyContent = (
      <div className={styles.passkeyContainer}>
        <span className={styles.passkeyText}>{dialogData.device_token}</span>
        {!copied ? (
          <FontAwesomeIcon
            icon={faClipboard}
            alt="clipboard"
            className={styles.copyIcon}
            onClick={() => copyToClipboard(dialogData.device_token)}
            title="Copy Passkey"
          />
        ) : (
          <FontAwesomeIcon
            icon={faClipboardCheck}
            alt="copiedToClipboard"
            className={styles.copyIcon}
            title="Passkey Copied"
          />
        )}
      </div>
    );
  } else if (error) {
    title = error;
    bodyContent = <p>{error.message}</p>;
  } else {
    title = "Unknown";
    bodyContent = <p>An unknown error occurred.</p>;
  }

  return (
    <div className={styles.dialogOverlay}>
      <div className={styles.dialog}>
        <h2 className={styles.dialogTitle}>{title}</h2>
        <div className={styles.dialogBody}>{bodyContent}</div>
        <button className={styles.dialogBtn} onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
}
