import React, { useEffect, useState } from "react";
import styles from "./ComplianceError.module.css";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { getComplianceWarnings } from "../../api/api";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";

export default function ComplianceError({ schedule, businessId }) {
  const [loading, setLoading] = useState(true);
  const [warnings, setWarnings] = useState([]);
  const initialStartDate = new Date();
  initialStartDate.setDate(initialStartDate.getDate() - 1);
  const [startTime, setStartTime] = useState(initialStartDate);
  const [endTime, setEndTime] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = async () => {
    try {
      const startTimeISO = startTime.toISOString();
      const endTimeISO = endTime.toISOString();
      const data = await getComplianceWarnings(
        businessId,
        schedule.id,
        startTimeISO,
        endTimeISO,
        currentPage
      );
      setWarnings(data.error_logs);
      setTotalPages(Math.ceil(data.total_count / data.page_limit));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching warnings list:", error);
      setLoading(false);
    }
  };

  // const formatDate = (date) => {
  //   return date.toISOString().split("T")[0];
  // };

  const handleFetchClick = async () => {
    try {
      fetchData();
    } catch (error) {
      console.error("Error fetching compliance warnings:", error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  let content;
  if (loading) {
    content = (
      <tr>
        <td colSpan="8" className={styles.spinner}>
          <div className={styles.loader}></div>
        </td>
      </tr>
    );
  } else if (!Array.isArray(warnings) || warnings?.length === 0) {
    content = (
      <tr className={styles.noData}>
        <td colSpan="8">No Compliance Warnings for this schedule location</td>
      </tr>
    );
  } else {
    content = warnings?.map((error, index) => (
      <tr className={styles.bodyTr} key={index}>
        <td>{error.terminal_id}</td>
        <td>{error.action_type}</td>
        <td>{new Date(error.created_at).toLocaleString()}</td>
        <td>{error.rule_code || "N/A"}</td>
        <td>{error.rule_name || "N/A"}</td>
        <td>{error.message}</td>
      </tr>
    ));
  }

  const prevButtonClasses = classNames(styles.prevButton, {
    [styles.disabled]: currentPage === 1 || warnings?.length === 0,
  });

  const nextButtonClasses = classNames(styles.nextButton, {
    [styles.disabled]: currentPage === totalPages || warnings?.length === 0,
  });

  const pagination = (
    <div className={styles.pagination}>
      <FontAwesomeIcon
        icon={faCircleChevronLeft}
        alt="previous"
        title="Previous"
        className={prevButtonClasses}
        onClick={() => {
          if (!(currentPage === 1 || warnings?.length === 0)) {
            handlePageChange(currentPage - 1);
          }
        }}
      />
      <span className={styles.pageSpan}>{currentPage}</span>
      <FontAwesomeIcon
        icon={faCircleChevronRight}
        alt="next"
        title="Next"
        className={nextButtonClasses}
        onClick={() => {
          if (!(currentPage === totalPages || warnings?.length === 0)) {
            handlePageChange(currentPage + 1);
          }
        }}
      />
    </div>
  );

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Compliance Warnings | LIFELENZ</title>
      </Helmet>
      <section className={styles.tableHeader}>
        <div className={styles.headerContent}>
          <span className={styles.headerSpan}>
            Time Clock | Compliance Warnings
          </span>
          <div
            className={styles.datePickers}
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Select the start date and end date"
            data-tooltip-place="left"
          >
            <div>
              <DatePicker
                className={styles.datePicker}
                selected={startTime}
                onChange={(date) => setStartTime(date)}
                showTimeSelect
                dateFormat="PPp"
                placeholderText="Start Date"
              />
            </div>

            <p className={styles.p}>- </p>
            <div>
              <DatePicker
                className={styles.datePicker}
                selected={endTime}
                onChange={(date) => setEndTime(date)}
                showTimeSelect
                dateFormat="PPp"
                minDate={startTime}
                placeholderText="End Date"
              />
            </div>

            <FontAwesomeIcon
              icon={faBolt}
              alt="fetch"
              className={styles.fetchButton}
              onClick={handleFetchClick}
            />
          </div>
        </div>
      </section>
      <main className={styles.mainTable}>
        <section className={styles.tableBody}>
          <table className={styles.complianceErrorTable}>
            <thead>
              <tr>
                <th>Badge Id</th>
                <th>Punch Type</th>
                <th>Punch Time</th>
                <th>Rule Code </th>
                <th>Rule Category</th>
                <th>Rule Description</th>
              </tr>
            </thead>
            <tbody>{content}</tbody>
          </table>
        </section>
        <section className={styles.tableFooter}>{pagination}</section>
      </main>
      <Tooltip />
    </div>
  );
}
